import React, { useState, useEffect } from "react";
import "./home.css";
import { motion } from "framer-motion";

const newsItems = [
  "Home Secretary launches Windrush Compensation Scheme",
  "Over 50,000 applications to the EU Settlement Scheme on opening weekend",
  "Dedicated support centres for complex cases",
  "Home Office launches nationwide campaign for EU Settlement Scheme",
];

const ImageBanner = () => {
  const [currentNews, setCurrentNews] = useState([...newsItems, ...newsItems]); 
  const [tickerPosition, setTickerPosition] = useState(0);

  useEffect(() => {
    const tickerSpeed = 2; 
    const tickerInterval = setInterval(() => {
      setTickerPosition((prevPosition) => {
        const newPosition = prevPosition - tickerSpeed;
        const tickerWidth = document.querySelector(".ticker")?.offsetWidth;
        const containerWidth = document.querySelector(".news-ticker")?.offsetWidth;

        if (Math.abs(newPosition) >= tickerWidth / 2) {
          return 0;
        }
        return newPosition;
      });
    }, 30); 

    return () => clearInterval(tickerInterval);
  }, []);

  return (
    <>
      <motion.div
        className="image-banner"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        <div className="overlay">
          <div className="text-container">
            <motion.p
              className="subtitle"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              SHORTLISTED FOR 'BOUTIQUE LAW FIRM OF THE YEAR' AT THE 2023 MODERN LAW AWARDS.
            </motion.p>
            <motion.h1
              className="title"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.5 }}
            >
              FOR IMMIGRATION MATTERS.
            </motion.h1>
            <motion.h1
              className="title"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.7 }}
            >
              WHEN IT MATTERS.
            </motion.h1>
            <motion.button
              className="cta-button"
              initial={{ scale: 0 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 0.5, delay: 0.9 }}
            >
              More
            </motion.button>
          </div>
        </div>
      </motion.div>

      {/* News ticker section */}
      <section className="news-ticker-section">
        <div className="news-ticker">
          <div className="ticker" style={{ transform: `translateX(${tickerPosition}px)` }}>
            {currentNews.map((news, index) => (
              <span key={index} className="news-item">
                {news}
              </span>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ImageBanner;
