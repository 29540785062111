import React from 'react';
import './ImmigrationLaw.css';
import Sidebar from '../../Sidebar/Sidebar'; // Import the Sidebar component

const ImmigrationLaw = () => {
  return (
    <div className="immigration-law-page">
     
     <Sidebar />
      <div className="immigration-law-content">
        <img
          src="/home.png" 
          alt="Immigration Law" 
          className="full-width-image"
        />
        <div className="content-wrapper">
          <h1>Immigration Law</h1>
          <p>
            At Highfin Solicitors, our professional team of immigration solicitors offers comprehensive advice on all elements of UK immigration law. Whether you are an individual wishing to join family, work, or study in the UK, or a business negotiating immigration restrictions, we provide straightforward, effective solutions suited to your specific requirements.
          </p>

          <h2>Personal Immigration</h2>
          <p>We assist clients through every stage of their immigration journey, from initial visa applications to securing Indefinite Leave to Remain (ILR) or British citizenship. Our services cover:</p>
          <ul>
            <li><strong>Family visas:</strong> Partner, spouse, and dependent relatives.</li>
            <li><strong>Fiancé(e) and civil partner visas.</strong></li>
            <li><strong>Naturalisation and British citizenship.</strong></li>
            <li><strong>Long residence applications.</strong></li>
            <li><strong>Post-Brexit EEA family permits.</strong></li>
          </ul>
          <p>
            We also handle complex human rights cases, supporting those facing deportation, immigration detention, or who require asylum. With a strong track record in defending human rights applications, our team ensures you receive the best possible representation.
          </p>

          <h2>Business Immigration</h2>
          <p>For companies and employers, we provide strategic advice on sponsoring foreign workers and navigating the UK’s immigration requirements. From Skilled Worker visas to Sponsorship Licences, our legal experts ensure full compliance with UK immigration rules, protecting your business interests.</p>
          <ul>
            <li><strong>Tier 2 (Skilled Worker) visas.</strong></li>
            <li><strong>Sole Representative visas.</strong></li>
            <li><strong>Innovator and Start-up visas.</strong></li>
            <li><strong>Intra-company transfer visas.</strong></li>
            <li><strong>Sponsor Licence applications.</strong></li>
          </ul>

          <h2>Asylum and Human Rights</h2>
          <p>
            We are highly experienced in handling asylum claims and human rights appeals. Whether representing individuals fleeing persecution or supporting unaccompanied minors and victims of trafficking, our team is committed to providing compassionate, expert legal support. We offer help with:
          </p>
          <ul>
            <li><strong>Asylum applications.</strong></li>
            <li><strong>Human rights applications.</strong></li>
            <li><strong>Appeals and judicial reviews.</strong></li>
            <li><strong>Family reunification for refugees.</strong></li>
          </ul>

          <h2>Detention and Bail</h2>
          <p>
            For those detained under UK immigration laws, we provide immediate support with bail applications and claims of unlawful detention. We work efficiently to secure your release and ensure your rights are fully upheld.
          </p>

          <h2>Why Choose Highfin Solicitors?</h2>
          <ul>
            <li><strong>Specialist Expertise:</strong> We cover every aspect of immigration law, from routine visa applications to complex appeals and asylum cases.</li>
            <li><strong>Tailored Advice:</strong> Each case is unique, and we provide legal advice specific to your circumstances to achieve the best possible outcome.</li>
            <li><strong>Proven Success:</strong> Our high success rates in immigration cases speak to the quality of our work and commitment to our clients.</li>
            <li><strong>Professional Standards:</strong> As an SRA-regulated firm, we maintain the highest standards of legal practice, ensuring you receive expert and ethical representation.</li>
          </ul>

          <p>
            If you're seeking professional advice on any immigration matter, trust Highfin Solicitors to provide the dedicated, expert guidance you need. Reach out to us today for a consultation.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ImmigrationLaw;
