import React from "react";
import ContactHeader from "./contactheader/contactheader";
import Contactform from "./contactform/contactform";

function Service() {
  return (
    <div>
      <ContactHeader />
      <Contactform/>
    </div>
  );
}

export default Service;
