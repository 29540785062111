import React, { useState } from "react";
import "./contactform.css";

function Contactus() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [submitted, setSubmitted] = useState(false); // Add state to track submission

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch("http://localhost:5000/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.text())
      .then((data) => {
        setSubmitted(true); // Set submitted to true
      })
      .catch((error) => {
        alert("Failed to send message. Try again later.");
      });
  };

  return (
    <div>
      {/* Contact Section */}
      <section className="contact-us">
        <div className="contact-container">
          {/* Contact Information */}
          <div className="contact-info">
            <h2>Contact Info</h2>
            <div className="contact-info-item">
              <i className="fas fa-phone"></i> <b>Phone:</b> 0207 113 5728
            </div>
            <div className="contact-info-item">
              <i className="fas fa-phone"></i> <b>Tel:</b> 07485 625750
            </div>
            <div className="contact-info-item">
              <i className="fas fa-envelope"></i> <b>Email:</b>{" "}
              info@highfinlimited.com
            </div>
            
            <div className="contact-info-item">
              <i className="fas fa-map-marker-alt"></i> <b>Address:</b> 25 North Row London W1K 6DJ
            </div>
            {/* Live Map */}
            <div className="map-container">
              <iframe
                title="Location Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.0039460926905!2d-0.15461359999999996!3d51.5131436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760532ee9ec8bb%3A0x54f701377b7c9dd0!2s25%20N%20Row%2C%20London%20W1K%206DJ%2C%20UK!5e0!3m2!1sen!2s!4v1727281017149!5m2!1sen!2s"
                width="100%"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>

          {/* Contact Form */}
          <div
            className={`contact-form-wrapper ${submitted ? "submitted" : ""}`}
          >
            <div className="form-overlay"></div>
            {!submitted ? (
              <div className="contact-form">
                <h2>Send Us A Message</h2>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="name">
                      <i className="fas fa-user"></i> Name:
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">
                      <i className="fas fa-envelope"></i> Email:
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="message">
                      <i className="fas fa-comment"></i> Message:
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      rows="5"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                  <button type="submit" className="send-button">
                    Send Message
                  </button>
                </form>
              </div>
            ) : (
              <div className="thank-you-message">
                <h2>Thank You!</h2>
                <p>
                  Thank you for contacting us. We will get back to you soon.
                </p>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contactus;
