import React from "react";

import ImmigrationLaws from "./ImmigerationLaw/ImmigerationLaw";



function ImmigrationLaw() {
  return (
    <div>
     <ImmigrationLaws/>
    </div>
  );
}

export default ImmigrationLaw;
