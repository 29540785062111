import React from "react";

import FamilyLaws from "./FamilyContent/FamilyLaw";



function FamilyLaw() {
  return (
    <div>
     <FamilyLaws/>
    </div>
  );
}

export default FamilyLaw;
