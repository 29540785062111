import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Navbar.css";

const Navbar = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [activeNestedSubMenu, setActiveNestedSubMenu] = useState(null);
  const [activeNestedNestedSubMenu, setActiveNestedNestedSubMenu] =
    useState(null);
  const [hideTopSection, setHideTopSection] = useState(false);
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  const handleHover = (menu) => {
    setActiveMenu(menu);
    setActiveSubMenu(null);
    setActiveNestedSubMenu(null);
    setActiveNestedNestedSubMenu(null);
  };

  const handleSubMenuClick = (subMenu) => {
    setActiveSubMenu((prev) => {
      const newState = prev === subMenu ? null : subMenu;
      console.log(`activeSubMenu set to: ${newState}`);
      return newState;
    });
    setActiveNestedSubMenu(null);
    setActiveNestedNestedSubMenu(null);
  };

  const handleNestedSubMenuClick = (nestedSubMenu, event) => {
    event.stopPropagation(); // Prevent event bubbling
    setActiveNestedSubMenu((prev) => {
      const newState = prev === nestedSubMenu ? null : nestedSubMenu;
      console.log(`activeNestedSubMenu set to: ${newState}`);
      return newState;
    });
    setActiveNestedNestedSubMenu(null);
  };

  const handleNestedNestedSubMenuClick = (nestedNestedSubMenu, event) => {
    event.stopPropagation(); // Prevent event bubbling
    setActiveNestedNestedSubMenu((prev) => {
      const newState =
        prev === nestedNestedSubMenu ? null : nestedNestedSubMenu;
      console.log(`activeNestedNestedSubMenu set to: ${newState}`);
      return newState;
    });
  };

  // Reset active menu when mouse leaves the menu
  const handleMouseLeave = () => {
    setActiveMenu(null);
    setActiveSubMenu(null);
    setActiveNestedSubMenu(null);
    setActiveNestedNestedSubMenu(null);
    console.log("Menu reset on mouse leave");
  };

  // Hide top section and fix navbar on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setHideTopSection(true);
        setIsNavbarFixed(true);
      } else {
        setHideTopSection(false);
        setIsNavbarFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Debugging: Log state changes
  useEffect(() => {
    console.log("activeMenu:", activeMenu);
    console.log("activeSubMenu:", activeSubMenu);
    console.log("activeNestedSubMenu:", activeNestedSubMenu);
    console.log("activeNestedNestedSubMenu:", activeNestedNestedSubMenu);
  }, [
    activeMenu,
    activeSubMenu,
    activeNestedSubMenu,
    activeNestedNestedSubMenu,
  ]);

  return (
    <div>
      {/* Top section (hidden when scrolling down) */}
      <div className={`top-section ${hideTopSection ? "hidden" : ""}`}>
        <div className="container">
          <div className="company-details">
            <a href="./">
              <img
                src="highfin-logo.png"
                alt="Small Logo"
                className="small-logo"
              />
            </a>
            <span>0203 755 2805</span>
          </div>
          <div className="search-section">
            <img
              src="google-plus.png"
              alt="Google+++"
              className="google-plus"
            />
            <input type="text" placeholder="Search" className="search-input" />
            <i className="fas fa-search search-icon"></i>
          </div>
          <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=100064690404654">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="/">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="/">
              <i className="fab fa-linkedin"></i>
            </a>
            <a href="/">
              <i className="fab fa-x-twitter"></i>
            </a>
          </div>
        </div>
      </div>

      {/* Navigation Bar */}
      <div className={`navbar ${isNavbarFixed ? "fixed" : ""}`}>
        <div className="container">
          <ul className="navbar-list">
            <li
              onMouseEnter={() => handleHover("practiceAreas")}
              onMouseLeave={handleMouseLeave}
            >
              Practice Areas
              {activeMenu === "practiceAreas" && (
                <div className="submenu grey-bg">
                  {/* Immigration Law Submenu */}
                  <div
                    className="submenu-item"
                    onClick={() => handleSubMenuClick("immigrationLaw")}
                  >
                    <a href="immigrationLaw">Immigration Law</a> <i className="fas fa-plus plus-icon"></i>
                    {activeSubMenu === "immigrationLaw" && (
                      <div className="nested-submenu white-bg">
                        {/* Personal Immigration Submenu */}
                        <div
                          className="submenu-item"
                          onClick={(event) =>
                            handleNestedSubMenuClick(
                              "personalImmigration",
                              event
                            )
                          }
                        >
                          Personal Immigration{" "}
                          <i className="fas fa-plus plus-icon"></i>
                          {activeNestedSubMenu === "personalImmigration" && (
                            <div className="nested-submenu white-bg">
                              <div
                                className="submenu-item"
                                onClick={(event) =>
                                  handleNestedNestedSubMenuClick(
                                    "visasPermits",
                                    event
                                  )
                                }
                              >
                                Visas and Permits{" "}
                                <i className="fas fa-plus plus-icon"></i>
                                {activeNestedNestedSubMenu ===
                                  "visasPermits" && (
                                  <div className="nested-submenu white-bg">
                                    <div className="submenu-item">
                                      Work Visas
                                    </div>
                                    <div className="submenu-item">
                                      Student Visas
                                    </div>
                                    <div className="submenu-item">
                                      Family Visas
                                    </div>
                                    <div className="submenu-item">
                                      Visitor Visas
                                    </div>
                                    <div className="submenu-item">
                                      Settlement Visas
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div
                                className="submenu-item"
                                onClick={(event) =>
                                  handleNestedNestedSubMenuClick(
                                    "Citizenship",
                                    event
                                  )
                                }
                              >
                                Citizenship Applications{" "}
                                <i className="fas fa-plus plus-icon"></i>
                                {activeNestedNestedSubMenu ===
                                  "Citizenship" && (
                                  <div className="nested-submenu white-bg">
                                    <div className="submenu-item">
                                      Naturalization
                                    </div>
                                    <div className="submenu-item">
                                      Registration
                                    </div>
                                    <div className="submenu-item">
                                      Dual Citizenship
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div
                                className="submenu-item"
                                onClick={(event) =>
                                  handleNestedNestedSubMenuClick(
                                    "asylumrefugee",
                                    event
                                  )
                                }
                              >
                                Asylum and Refugee Cases{" "}
                                <i className="fas fa-plus plus-icon"></i>
                                {activeNestedNestedSubMenu ===
                                  "asylumrefugee" && (
                                  <div className="nested-submenu white-bg">
                                    <div className="submenu-item">
                                      Asylum Applications
                                    </div>
                                    <div className="submenu-item">
                                      Refugee Status Determination
                                    </div>
                                    <div className="submenu-item">
                                      Appeals and Judicial Review
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div
                                className="submenu-item"
                                onClick={(event) =>
                                  handleNestedNestedSubMenuClick(
                                    "deportation",
                                    event
                                  )
                                }
                              >
                                Deportation Defense{" "}
                                <i className="fas fa-plus plus-icon"></i>
                                {activeNestedNestedSubMenu ===
                                  "deportation" && (
                                  <div className="nested-submenu white-bg">
                                    <div className="submenu-item">
                                      Removal Proceedings
                                    </div>
                                    <div className="submenu-item">
                                      Detention and Bail
                                    </div>
                                    <div className="submenu-item">
                                      Appeals and Judicial Review
                                    </div>
                                    <div className="submenu-item">
                                      Human Rights Application
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        {/* Business Immigration Submenu */}
                        <div
                          className="submenu-item"
                          onClick={(event) =>
                            handleNestedSubMenuClick(
                              "businessImmigration",
                              event
                            )
                          }
                        >
                          <a href="/businessimmigration">Business Immigration</a>{" "}
                          <i className="fas fa-plus plus-icon"></i>
                          {activeNestedSubMenu === "businessImmigration" && (
                            <div className="nested-submenu white-bg">
                              <div className="submenu-item">
                                Corporate Visas
                              </div>
                              <div className="submenu-item">Investor Visas</div>
                              <div className="submenu-item">
                                Employment Passes
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* Other Submenu Items */}
                  <div
                    className="submenu-item"
                    onClick={(event) =>
                      handleNestedSubMenuClick("familyLaw", event)
                    }
                  >
                    <a href="/FamilyLaw">Family Law</a> <i className="fas fa-plus plus-icon"></i>
                    {activeNestedSubMenu === "familyLaw" && (
                      <div className="nested-submenu white-bg">
                        <div className="submenu-item">
                          Divorce and Separation
                        </div>
                        <div className="submenu-item">
                          Child Custody and Support
                        </div>
                        <div className="submenu-item">Adoption</div>
                        <div className="submenu-item">
                          Prenuptial Agreements
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="submenu-item"
                    onClick={(event) =>
                      handleNestedSubMenuClick("litigation", event)
                    }
                  >
                    Litigation <i className="fas fa-plus plus-icon"></i>
                    {activeNestedSubMenu === "litigation" && (
                      <div className="nested-submenu white-bg">
                        <div className="submenu-item">Civil Litigation</div>
                        <div className="submenu-item">
                          Commercial Litigation
                        </div>
                        <div className="submenu-item">Dispute Resolution</div>
                      </div>
                    )}
                  </div>
                  {/* Housing Submenu */}
                  <div
                    className="submenu-item"
                    onClick={(event) =>
                      handleNestedSubMenuClick("housing", event)
                    }
                  >
                    Housing <i className="fas fa-plus plus-icon"></i>
                    {activeNestedSubMenu === "housing" && (
                      <div className="nested-submenu white-bg">
                        <div className="submenu-item">
                          Landlord-Tenant Disputes
                        </div>
                        <div className="submenu-item">Evictions</div>
                        <div className="submenu-item">Housing Disrepair</div>
                        <div className="submenu-item">Homelessness</div>
                      </div>
                    )}
                  </div>

                  {/* Wills and Probate Submenu */}
                  <div
                    className="submenu-item"
                    onClick={(event) =>
                      handleNestedSubMenuClick("willsProbate", event)
                    }
                  >
                    Wills and Probate <i className="fas fa-plus plus-icon"></i>
                    {activeNestedSubMenu === "willsProbate" && (
                      <div className="nested-submenu white-bg">
                        <div className="submenu-item">Will Drafting</div>
                        <div className="submenu-item">
                          Estate Administration
                        </div>
                        <div className="submenu-item">Probate Disputes</div>
                        <div className="submenu-item">
                          Inheritance Tax Planning
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </li>

            <li
              onMouseEnter={() => handleHover("aboutUs")}
              onMouseLeave={handleMouseLeave}
            >
              <a href="/About">About Us</a>
              {activeMenu === "aboutUs" && (
                <div className="submenu grey-bg">
                  <div
                    className="submenu-item"
                    onClick={() => handleSubMenuClick("ourTeam")}
                  >
                    Why Highfin
                  </div>
                  <div
                    className="submenu-item"
                    onClick={() => handleSubMenuClick("ourTeam")}
                  >
                    <a href="./ourteam">Our People</a>
                  </div>
                  <div
                    className="submenu-item"
                    onClick={() => handleSubMenuClick("ourValues")}
                  >
                    Our Fees
                  </div>
                  <div
                    className="submenu-item"
                    onClick={() => handleSubMenuClick("ourTeam")}
                  >
                    Career
                  </div>
                </div>
              )}
            </li>

            <li
              onMouseEnter={() => handleHover("knowledgeHub")}
              onMouseLeave={handleMouseLeave}
            >
              Knowledge Hub
              {activeMenu === "knowledgeHub" && (
                <div className="submenu grey-bg">
                  <div
                    className="submenu-item"
                    onClick={() => handleSubMenuClick("news")}
                  >
                    News
                  </div>
                  <div
                    className="submenu-item"
                    onClick={() => handleSubMenuClick("blog")}
                  >
                    Blog
                  </div>
                </div>
              )}
            </li>
            
            <a href="./contactus">
              <li>Contact Us</li>
            </a>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
