import React from "react";
import Team from "./Profiles/profile";

function OurTeam() {
  return (
    <div>
      <Team />
    </div>
  );
}

export default OurTeam;
