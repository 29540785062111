import React from "react";

import AboutUs from "./AboutSection/AboutPage";

function Home() {
  return (
    <div>
     <AboutUs/>
    </div>
  );
}

export default Home;
