import React from 'react';
import './profile.css'; // Your CSS file for styling

const teamMembers = [
  {
    name: 'Toufik Meddah',
    role: 'Managing Director and Legal Research',
    img: './boy_team.jpg', 
  },
  {
    name: 'Frances Valenzuela',
    role: 'Head of Family',
    img: 'girl_team.png', 
  },
  {
    name: 'Henry Lau',
    role: 'Head of Immigration and Family',
    img: './girl_team.png', 
  },
  {
    name: 'Colin Faragher',
    role: 'Researcher & Head of Public Law',
    img: './boy_team.jpg', 
  },
  {
    name: 'Russell Leckstein',
    role: 'Senior Litigation Consultant',
    img: './boy_team.jpg', 
  },
];

const OurTeam = () => {
  return (
    <div className="our-team-container">
      <h1 className="team-heading">Our Team</h1>

      <div className="cover-image-container">
        <img src="./TeamHead.jpg" alt="Team Cover" className="cover-image" /> {/* Replace with actual cover image path */}
      </div>

      <div className="team-intro">
        <p>
        Highfin Limited is UK based firm of solicitors located in Central London. We are regulated by the Solicitors Regulation Authority, Highfin Limited aims to offer high quality legal services to all our clients, making justice available to all by prioritizing clients’ needs at an affordable cost while providing a friendly and supportive service from start to finish.
        </p>
      </div>

      <div className="team-profiles">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-member-card">
            <img src={member.img} alt={`${member.name}'s profile`} className="member-image" />
            <div className="member-info">
              <h2 className="member-name">{member.name}</h2>
              <p className="member-role">{member.role}</p>
              <p className="member-description">{member.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurTeam;
