import React from "react";

import Homesection from "./Homesection/home";
import TwoColumnSection from "./Homesection/TwoColumnSection";
import InnerBanner from "./Homesection/innerbanner";
import Services from "./Homesection/Services";
import WhyChooseUs from "./Homesection/WhyChooseUs";


function Home() {
  return (
    <div>
      <Homesection />
      <TwoColumnSection/>
      <Services/>
      {/* <InnerBanner/> */}
      <WhyChooseUs/>
    </div>
  );
}

export default Home;
