import React from 'react';
import './PrivacyPolicy.css'; 

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      
      <section>
        <h2>Who We Are</h2>
        <p>
          We take the privacy and confidentiality of our clients, potential clients, ex-clients and their affiliates very seriously and we are committed to protecting it. This Privacy Policy explains the type of information we collect, why we collect it, how we use it, who we might share it with and how to correct or modify it.
        </p>
      </section>
      
      <section>
        <h2>How do we collect your information?</h2>
        <p>
          Most of the personal information we process is provided to us directly by you in relation to a legal claim or legal advice. We might collect this information from you in a number of ways, including via our website (e.g. our online enquiry form), over the telephone or via email. The personal information you supply to us may include: your name, address, contact details, date of birth, details of the circumstances of any claim or incident and any damage, injury or loss suffered by you, National Insurance number, employment and GP details.
        </p>
        <p>
          We may also receive personal information indirectly, for example:
        </p>
        <ul>
          <li>By contacting third parties in connection with the services we provide to you, for example, medical or other experts;</li>
          <li>If a claimant or a third party refers to you in correspondence or documentation relating to a legal claim;</li>
          <li>From our suppliers, including introducers or referrers;</li>
          <li>From Credit Reference Agencies;</li>
          <li>From public authorities, regulators or law enforcement bodies; or</li>
          <li>An employee of ours gives your contact details as an emergency contact or a referee.</li>
        </ul>
        <p>
          Please note that if you are a third party and we are processing your information in connection with legal proceedings, and/or if legal professional privilege applies, then it is likely we are exempt from any requirement to inform you that we are processing your information.
        </p>
      </section>
      
      <section>
        <h2>We may use your personal information:</h2>
        <ul>
          <li>To provide legal or other services;</li>
          <li>To perform credit reference or other regulatory checks;</li>
          <li>To engage the services of a third party (where instructed);</li>
          <li>For the purposes of operating our business (such as management information);</li>
          <li>To comply with legal and regulatory obligations;</li>
          <li>To contact you in relation to customer surveys or market research; or</li>
          <li>To send you legal updates, or information in relation to products and services that may be of interest to you from Highfin Solicitors Limited.</li>
        </ul>
      </section>
      
      <section>
        <h2>Your rights</h2>
        <p>You have a number of legal rights over the personal information held by us:</p>
        <ul>
          <li>The right to access your personal information;</li>
          <li>The right to correct or update any personal information;</li>
          <li>The right to object to further processing;</li>
          <li>The right to restrict the processing of your personal information;</li>
          <li>The right to ask us to delete your personal information;</li>
          <li>The right to receive the personal information we hold about you in a portable format.</li>
        </ul>
      </section>

      <section>
        <h2>Changes to this Privacy Notice</h2>
        <p>
          We may amend this policy at any time by either notifying you or posting a revised version on our website.
        </p>
        <p>
          If we make a change to this policy, we will take your continued use of our services after that date as your acceptance of the change.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
