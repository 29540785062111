import React, { useEffect, useRef } from 'react';
import './CustomCursor.css'; // Your CSS file

const CustomCursor = () => {
  const cursorRef = useRef(null);
  const positionRef = useRef({
    mouseX: 0,
    mouseY: 0,
    currentX: 0,
    currentY: 0,
    velocityX: 0,
    velocityY: 0,
  });

  useEffect(() => {
    const handleMouseMove = (e) => {
      positionRef.current.mouseX = e.clientX;
      positionRef.current.mouseY = e.clientY;
    };

    const animateCursor = () => {
      const { mouseX, mouseY, currentX, currentY, velocityX, velocityY } = positionRef.current;

      const distanceX = mouseX - currentX;
      const distanceY = mouseY - currentY;

      positionRef.current.velocityX = velocityX * 0.8 + distanceX * 0.2;
      positionRef.current.velocityY = velocityY * 0.8 + distanceY * 0.2;

      positionRef.current.currentX += positionRef.current.velocityX;
      positionRef.current.currentY += positionRef.current.velocityY;

      if (cursorRef.current) {
        cursorRef.current.style.transform = `translate3d(${positionRef.current.currentX}px, ${positionRef.current.currentY}px, 0)`;
      }

      requestAnimationFrame(animateCursor);
    };

    window.addEventListener('mousemove', handleMouseMove);
    animateCursor();

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return <div className="custom-cursor" ref={cursorRef} />;
};

export default CustomCursor;
