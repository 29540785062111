import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import './App.css';

import CustomCursor from './Components/CustomCursor';
import Navbar from "./Components/Navbar"; 
import Footer from "./Components/Footer";
import Home from "./Components/Home/Home";
import About from "./Components/About/About";
import ContactUs from "./Components/ContactUs/ContactUs";
import OurTeam from "./Components/OurTeam/OurTeam";
import PrivacyPolicy from "./Components/Privacy/PrivacyPolicy";
import BusinessImmigration from "./Components/BusinessImmigration/BusinessImmigration";
import ImmigrationLaw from "./Components/ImmigrationLaw/ImmigerationMain";
import FamilyLaw from "./Components/FamilyLaw/FamilyLaw";

function App() {
  return (
    <Router>
      <div className="App">
        <CustomCursor />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/ourteam" element={<OurTeam />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/businessimmigration" element={<BusinessImmigration />} />
          <Route path="/immigrationlaw" element={<ImmigrationLaw />} />
          <Route path="/familylaw" element={<FamilyLaw />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
