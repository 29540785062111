import React from 'react';
import './BusinessImmigration.css';
import Sidebar from '../Sidebar/Sidebar'; 

const BusinessImmigration = () => {
  return (
    <div className="immigration-law-page">
     
     <Sidebar />
       <div className="business-immigration-content">
        <h1>Highfin Solicitors – Your Trusted Partner for Business Immigration Solutions</h1>
        <p>
          At Highfin Solicitors, we provide expert advice and comprehensive legal services in business immigration. 
          Widely regarded as a leading law firm in London, our experienced team supports clients from diverse industries, 
          ranging from individuals and startups to small and medium-sized enterprises (SMEs) and multinational corporations.
        </p>
        <p>
          Whether you're looking to expand your business, hire international talent, or secure visas for key personnel, 
          we’re here to assist with every aspect of business immigration, offering tailored guidance that aligns with your specific needs.
        </p>

        {/* Services List */}
        <h3>Our Business Immigration Services</h3>
        <ol>
          <li>
            <strong>Tier 2 Sponsorship Licence:</strong>
            We handle all aspects of Tier 2 sponsorship applications, including new applications, renewals, audits, compliance, and revocation issues.
          </li>
          <li>
            <strong>Global Immigration & Intra-Company Transfers:</strong>
            We advise on global mobility solutions for businesses that need to transfer employees between international offices.
          </li>
          <li>
            <strong>Innovator Visa:</strong>
            For entrepreneurs looking to establish innovative businesses in the UK, we provide expert guidance on the Innovator Visa.
          </li>
          <li>
            <strong>Sole Representative Visa:</strong>
            We assist with the Sole Representative Visa, enabling key representatives to set up a branch or subsidiary in the UK.
          </li>
          <li>
            <strong>Global Business Mobility Visas:</strong>
            We assist businesses navigating the Global Business Mobility Visa scheme, including UK Expansion Worker and Graduate Trainee Visas.
          </li>
          <li>
            <strong>Tier 1 Investor Visa:</strong>
            We offer dedicated advice on securing a Tier 1 Investor Visa for high-net-worth individuals looking to invest in the UK.
          </li>
        </ol>

        {/* Why Choose Us Section */}
        <h3>Why Choose Highfin Solicitors for Business Immigration?</h3>
        <ul>
          <li><strong>Industry Expertise:</strong> We have vast experience across multiple sectors, including technology, education, finance, and healthcare.</li>
          <li><strong>Award-Winning Team:</strong> Our team includes some of the UK’s most reputable immigration lawyers with a proven track record of success.</li>
          <li><strong>Compliance & Strategy:</strong> We provide full support for businesses, helping them avoid costly penalties or sponsorship licence revocation.</li>
        </ul>

        {/* Comprehensive Support for Corporate Clients */}
        <h3>Comprehensive Support for Corporate Clients</h3>
        <ul>
          <li><strong>Sponsorship Licence Applications & Compliance:</strong> We guide businesses through obtaining and maintaining a Sponsorship Licence.</li>
          <li><strong>Global Mobility & Employee Transfers:</strong> We assist multinational companies in managing the global movement of employees.</li>
          <li><strong>Skilled Worker Visas (Tier 2):</strong> We manage the entire visa process, from eligibility to application submissions.</li>
          <li><strong>Entrepreneur & Investor Visas:</strong> We provide specialist advice for entrepreneurs and investors looking to establish or invest in the UK.</li>
        </ul>

        {/* How We Help Section */}
        <h3>How We Help Your Business Thrive</h3>
        <ul>
          <li><strong>Tailored Advice:</strong> We offer bespoke solutions aligned with your business’s unique immigration needs.</li>
          <li><strong>Compliance & Risk Management:</strong> We help your HR and legal departments stay compliant with immigration laws, avoiding costly mistakes.</li>
          <li><strong>Strategic Immigration Planning:</strong> We provide long-term strategies to ensure your business is prepared for future growth and expansion.</li>
        </ul>

        {/* Contact Section */}
        <h3>Get in Touch</h3>
        <p>
          At Highfin Solicitors, we are committed to delivering exceptional legal advice for all your business immigration needs. 
          Whether you're a start-up looking to bring in global talent or a multinational corporation navigating complex visa regulations, our team is here to help.
        </p>
        <p>
          Contact us today on <a href="tel:02037552805">0203 755 28 05</a> or via our <a href="#">online inquiry form</a> to speak with one of our expert business immigration solicitors and start securing your company’s future.
        </p>
        <p className="Expert"><strong>Highfin Solicitors – Expert Guidance for All Your Business Immigration Needs.</strong></p>
      </div>
    </div>
  );
};

export default BusinessImmigration;


