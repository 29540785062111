import React from "react";
import "./Aboutpage.css"; // You can create a separate CSS file for styling

const AboutUs = () => {
  return (
    <div className="about-us-content">
      <h2>About Us</h2>
      <p>
        Highfin Solicitors is a London-based legal firm, regulated by the Solicitors Regulation Authority (SRA), committed to delivering exceptional legal services across a wide range of practice areas. Established with a client-first approach, Highfin Solicitors prides itself on its transparent, professional, and reliable legal counsel.
      </p>
      <p>
        As an SRA-regulated firm (SRA number: 647385), we adhere strictly to the highest ethical and professional standards, ensuring that our clients benefit from enhanced protection and service quality. We offer legal expertise in areas such as family law, immigration, probate, litigation, and more. Our team of dedicated solicitors is equipped to handle both personal and commercial legal matters with efficiency and integrity.
      </p>
      <p>
        At Highfin Solicitors, we believe in making the legal process as smooth and understandable as possible for our clients. Whether you are dealing with complex legal disputes or preparing a will, we provide tailored legal solutions that meet your specific needs.
      </p>
      <p>
        Our firm's commitment to professionalism is backed by insurance protections and a compliance structure designed to safeguard our clients. If challenges arise, our clients can trust that we are fully equipped to assist with recovering documents, funds, or resolving legal disputes.
      </p>
      <p>
        We invite you to get in touch with our experienced team and discover how Highfin Solicitors can assist you in navigating your legal needs with confidence and peace of mind.
      </p>

      <h2>Our Values</h2>
      <h3>Highfin Solicitors</h3>
      <p>
        At Highfin Solicitors, our core values underpin everything we do, ensuring that our clients receive not only expert legal guidance but also an exceptional standard of service that reflects integrity, transparency, and client-focused care.
      </p>

      <h4>Integrity</h4>
      <p>
        We hold ourselves to the highest ethical standards, in accordance with the guidelines set by the Solicitors Regulation Authority (SRA). Our commitment to acting with integrity means that we approach every legal matter with honesty, fairness, and professionalism. We ensure that our clients are fully informed and that their interests are prioritised in all decisions.
      </p>

      <h4>Client-Centred Approach</h4>
      <p>
        At the heart of Highfin Solicitors is a dedication to providing a personalised service to each and every client. We understand that legal issues can be complex and stressful, so we strive to offer clear, practical advice tailored to individual needs. Our solicitors take the time to listen, ensuring that our solutions align with the specific goals and circumstances of our clients.
      </p>

      <h4>Transparency</h4>
      <p>
        We believe in transparency across all aspects of our service. From clear communication to straightforward fee structures, our clients can trust that there are no hidden surprises. We are committed to being open and honest about the costs, timelines, and potential outcomes of every case, allowing our clients to make informed decisions with confidence.
      </p>

      <h4>Excellence</h4>
      <p>
        At Highfin Solicitors, we pursue excellence in everything we do. Our legal team comprises highly experienced professionals who continually strive to stay at the forefront of legal developments. This enables us to offer innovative and effective solutions to even the most complex legal challenges.
      </p>

      <h4>Accountability</h4>
      <p>
        We are fully compliant with the SRA’s regulations, which guarantee that all of our services meet stringent professional and ethical standards. We take full responsibility for the quality of our legal advice and the outcomes we deliver, ensuring that our clients can trust us to manage their matters diligently and responsibly.
      </p>

      <h4>Compassion</h4>
      <p>
        We recognise that many legal matters are deeply personal. Whether clients are dealing with family disputes, immigration issues, or estate planning, we approach every case with empathy and understanding. Our team is committed to providing support that extends beyond legal expertise, offering reassurance and guidance during challenging times.
      </p>

      <p>
        At Highfin Solicitors, our values are more than just words; they are the principles that guide our practice. We invite you to experience a legal service built on trust, excellence, and unwavering commitment to your success.
      </p>
    </div>
  );
};

export default AboutUs;
