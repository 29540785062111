import React from "react";


import Privacy from "./PrivacyContent/PrivacyPolicy";





function PrivacyPolicy () {
  return (
    <div>
      <Privacy/>
    </div>
  );
}

export default PrivacyPolicy ;
