import React from "react";
import "./WhyChooseUs.css";

const WhyChooseUs = () => {
  return (
    <section className="why-choose-us-section">
      <div className="container">
        <div className="why-choose-us-content">
          <h2 className="section-title">Why Choose Us?</h2>
          <p className="section-description">
            As an <strong>SRA-regulated firm</strong> located in the heart of{" "}
            <strong>London’s Mayfair</strong>, we offer expert legal support with a client-first approach. Our team provides practical solutions tailored to your specific needs, ensuring reliable and professional advice every step of the way.
          </p>
          <a>
          <button className="cta-button1">Contact Us Today</button></a>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
