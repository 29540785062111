import React from 'react';
import './FamilyLaw.css';
import Sidebar from '../../Sidebar/Sidebar'; // Import the Sidebar component

const FamilyLaw = () => {
  return (
    <div className="immigration-law-page">
     
     <Sidebar />
       <div className="content-wrapper">
        <h1>Family Law</h1>
        <h2>Highfin Solicitors – Your Trusted Family Law Experts in the UK</h2>
        <p>
          At Highfin Solicitors, we recognize that family law matters can be deeply personal and emotionally challenging, especially when children are involved. Our priority is to provide compassionate, effective legal support to help you navigate these sensitive issues with minimal stress. Our aim is to resolve disputes with as little strain as possible on all parties, fostering solutions that focus on the wellbeing of your family.
        </p>

        <h3>Comprehensive Family Law Services</h3>
        <p>We offer expert advice and representation in the following areas:</p>
        <ul>
          <li><strong>Divorce & Judicial Separation:</strong> From standard divorce proceedings to high-net-worth divorces, we ensure your rights are protected.</li>
          <li><strong>Dissolution of Civil Partnerships:</strong> We guide you through the process, ensuring a smooth dissolution of civil partnerships with dignity and fairness.</li>
          <li><strong>Pre & Post Nuptial Agreements:</strong> Safeguard your assets and establish clear financial agreements before or after marriage.</li>
          <li><strong>Separation Agreements:</strong> Providing legal clarity and protection during separations without immediate divorce.</li>
          <li><strong>Cohabitation Agreements:</strong> For unmarried couples, we offer advice on protecting your financial interests and property rights.</li>
          <li><strong>Children’s Matters:</strong> Including child custody, visitation, and child support arrangements, ensuring the best outcome for your children.</li>
          <li><strong>Domestic Violence:</strong> We provide urgent and confidential legal support for victims of domestic abuse, including protective injunctions.</li>
        </ul>

        <h3>Encouraging Out of Court Resolutions</h3>
        <p>
          Wherever possible, we encourage amicable solutions through negotiation and conciliation, avoiding the stress and expense of court proceedings. We believe in promoting harmony and cooperation, particularly in matters concerning children, where long-term relationships between parties often need to be preserved.
        </p>

        <h3>Tailored Legal Support at Competitive Rates</h3>
        <p>At Highfin Solicitors, we offer:</p>
        <ul>
          <li><strong>Legal Aid:</strong> Available for many family and children law cases.</li>
          <li><strong>Fixed Fee Services:</strong> For divorce, judicial separation, civil partnership dissolution, and some children or domestic violence matters.</li>
          <li><strong>Competitive Hourly Rates:</strong> For more complex cases, including prenuptial agreements, cohabitation agreements, and separation agreements.</li>
        </ul>

        <h3>Why Choose Highfin Solicitors?</h3>
        <ul>
          <li><strong>Experienced & Compassionate Team:</strong> Our solicitors are legal experts and empathetic listeners who understand the emotional challenges you face.</li>
          <li><strong>Personalized Solutions:</strong> We approach each case individually, offering advice and support that aligns with your unique circumstances.</li>
          <li><strong>Proven Track Record:</strong> Whether through out-of-court settlements or court proceedings, our team has a successful history of achieving positive outcomes for our clients.</li>
          <li><strong>Child-Centered Approach:</strong> When children are involved, their welfare is our primary focus. We strive to create stable, child-centered arrangements that benefit the whole family.</li>
        </ul>

        <p>
          Contact Highfin Solicitors today for confidential, expert advice. Call us on <a href="tel:02037552805">020 3755 2805</a> or fill out our <a href="/contact-form">online inquiry form</a> to schedule a consultation.
        </p>
      </div>
    </div>
  );
};

export default FamilyLaw;



