import React from "react";
import "./contactheader.css";

function ContactHeader() {
  return (
    <header className="Contact-header">
      <div className="Contact-static-background">
        <div className="Contact-content">
          <h1>Contact Us</h1>
          <p>
          Contact us today for further information​
          </p>
        </div>
      </div>
    </header>
  );
}
export default ContactHeader;
