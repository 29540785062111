import React from 'react';
import './Sidebar.css'; 

const Sidebar = () => {
  return (
    <aside className="sidebar">
    <h2>Quick Contact</h2>
    <p>Need urgent assistance? Call us:</p>
    <p><strong>Phone:</strong> 0207 113 5728</p>
    <p><strong>Email:</strong> info@highfinlimited.com</p>

    <h2>Get a Consultation</h2>
    <button className="consultation-button"><a href='./contactus'>Book a Free Consultation</a></button>

    <h2>Client Testimonials</h2>
    <blockquote>
      "Highfin Solicitors helped me secure my visa quickly and efficiently. Highly recommend!"
      <cite> - John Doe</cite>
    </blockquote>
    <blockquote>
      "Their expertise in immigration law is unmatched. They made a stressful process smooth."
      <cite> - Sarah Khan</cite>
    </blockquote>

    <h2>Resources</h2>
    <ul className="resources-list">
      <li><a href="/downloads/immigration-guide.pdf" target="_blank">Immigration Law Guide</a></li>
      <li><a href="/downloads/visa-checklist.pdf" target="_blank">Visa Application</a></li>
    </ul>
  </aside>
  );
};

export default Sidebar;
