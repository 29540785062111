import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-logo-socials">
        <img
          src="highfin-logo.png"
          alt="Davidson Morris Logo"
          className="footer-logo"
        />
        <div className="footer-social-icons">
          <a href="https://www.facebook.com/profile.php?id=100064690404654"><i className="fab fa-facebook"></i></a>
          <a href="/"><i className="fab fa-instagram"></i></a>
          <a href="/"> <i className="fab fa-linkedin"></i></a>
          <a href="/"> <i className="fab fa-twitter"></i></a>
        </div>
      </div>

      <hr className="footer-divider" />

      <div className="footer-columns">
        <div className="footer-column">
          <h4>Company</h4>
          <ul>
            <li>Contact</li>
            <li>About</li>
            <li>Our people</li>
            <li>Careers</li>
            <li>Complaints procedure</li>
            <li>SRA price & service transparency</li>
          </ul>
        </div>

        <div className="footer-column">
          <h4>Services</h4>
          <ul>
            <li>Immigration</li>
            <li>Employment</li>
            <li>Human Resources</li>
            <li>Global Mobility</li>
          </ul>
        </div>

        <div className="footer-column">
          <h4>Sectors</h4>
          <ul>
            <li>Health & Social Care Sector</li>
            <li>Local Government Sector</li>
            <li>Energy Sector</li>
            <li>Tech Sector</li>
            <li>Education Sector</li>
            <li>Financial Services Sector</li>
            <li>Manufacturing Sector</li>
            <li>Retail Sector</li>
            <li>Travel & Leisure Sector</li>
          </ul>
        </div>

        <div className="footer-column">
          <h4>Resources</h4>
          <ul>
            <li>Sponsorship Hub</li>
            <li>Insights for Employers</li>
            <li>Immigration Insights</li>
            <li>Employment Law Insights</li>
            <li>HR Insights</li>
            <li>Global Mobility Insights</li>
            <li>Training & Events</li>
          </ul>
        </div>
      </div>

      {/* New Section Below Footer */}
      <div className="legal-footer">
        <div className="legal-section-left">
          {/* Insert the SRA Digital Badge iframe code here */}
          <div style={{ maxWidth: "275px", margin: "0 auto", display: "block" }}>
        <div style={{ position: "relative", height: "auto", overflow: "hidden" }}>
          <iframe
            frameBorder="0"
            scrolling="no"
            allowTransparency="true"
            src="https://cdn.yoshki.com/iframe/55845r.html"
            style={{
              border: "0px",
              margin: "0px",
              padding: "0px",
              backgroundColor: "transparent",
              top: "0px",
              left: "0px",
              width: "100%",
              height: "100%"
            }}
          ></iframe>
        </div>
      </div>
        </div>

        <div className="legal-section-right">
          <p>Highfin Limited authorised and regulated by the Solicitors Regulation Authority. London: SRA No 647385.</p>
          <p>Regulated by the Solicitors Regulation</p>
          <p>© Copyright 2024</p>
          <p><a href="/terms">Terms of Use</a> | <a href="/privacy">Privacy Policy</a> | <a href="/cookies">Cookies Notice</a></p>
        </div>
      </div>

      <div className="solworx">
  <p>© 2019 Highfin Limited | Powered by: <a href="#">Solworx Technologies Ltd</a></p>
</div>

    </footer>
  );
};

export default Footer;
