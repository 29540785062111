import React from "react";
import "./twocolumns.css";
import { motion } from "framer-motion";

const TwoColumnSection = () => {
  return (
    <div>
      <div className="two-column-section">
        <div className="left-column">
          <motion.h2
            whileHover={{ scale: 1.05, color: "#ff7f50" }}
            transition={{ duration: 0.3 }}
          >
            Welcome to Highfin Solicitors
          </motion.h2>
          <motion.p whileHover={{ scale: 1.02 }} transition={{ duration: 0.3 }}>
            A trusted SRA-regulated law firm based in Mayfair, London. We
            provide expert legal services in immigration law, family law,
            litigation, housing, and wills and probate. Our team is dedicated to
            delivering clear, practical advice tailored to your needs.
          </motion.p>
          <motion.button
            className="cta-button"
            whileHover={{
              scale: 1.05,
              backgroundColor: "#ff7f50",
              color: "#fff",
            }}
            transition={{ duration: 0.3 }}
          >
            More
          </motion.button>
        </div>

        <div className="right-column1">
          <h2>VISA & IMMIGRATION SERVICES FOR:</h2>
          <ul>
            <li>➦ INDIVIDUALS</li>
            <li>➦ BUSINESSES</li>
            <li>➦ LEGAL REPRESENTATIVES</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TwoColumnSection;
