import React from "react";
import { motion } from "framer-motion";
import "./Services.css";

const Services = () => {
  const cards = [
    {
      image: "1.jpg",
      title: "Immigration Law",
      description: "Whether you're applying for a visa, seeking citizenship, or facing deportation, we’re here to guide you. Our experienced team also supports businesses with corporate immigration, helping them comply with UK regulations."
    },
    {
      image: "2.jpg",
      title: "Family Law",
      description: "We handle sensitive family matters, including divorce, child custody, and domestic violence cases. Whatever the situation, we focus on protecting your rights and finding the best resolution for your family."
    },
    {
      image: "3.jpg",
      title: "Litigation",
      description: "From contract disputes to professional negligence claims, our litigation team provides strong representation. We aim for swift resolutions, whether through court proceedings or alternative dispute resolution."
    }    
  ];

  return (
    <div className="card-section">
      {cards.map((card, index) => (
        <motion.div
          className="card"
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          whileHover={{ scale: 1.05, rotate: 3, transition: { duration: 0.2 } }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.3, delay: index * 0.1 }} // Staggered delay for animation
        >
          <motion.img
            src={card.image}
            alt={`Card ${index + 1}`}
            className="card-image"
            initial={{ y: 0 }}
            whileHover={{ y: -10 }}
            transition={{ duration: 0.3 }}
          />
          <div className="card-content">
            <h3>{card.title}</h3>
            <p>{card.description}</p>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default Services;
